import React from 'react'
import '../assets/Css/blog.css';
import { MdArrowOutward } from "react-icons/md";

export default function Article({name, photo}) {
    return (
        <div className="card column">
            <div className="image">
                <img src={photo} alt="image" className='blogImg'/>
            </div>
            <div className="cardContent">
                <p>{name} <MdArrowOutward /></p>
            </div>
        </div>
    )
}
