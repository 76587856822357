export const ARTICLES = [
    {
        name: "The Role of Social Work in Education",
        photo: "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1710744409/sevinj-aliyeva-blog-media/blog-covers/gomo0tr4w36zm9c634ji.jpg",
        link: "https://www.academia.edu/116286054/THE_ROLE_OF_SOCIAL_WORK_IN_EDUCATION_AN_INNOVATIVE_VIEW_OF_THE_SCHOOL_ENVIRONMENT"
    },
    
    {
        name: "The Impact of War on Children",
        photo: "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1710744700/sevinj-aliyeva-blog-media/blog-covers/dy5asoevhwmqyjmmqlpq.jpg",
        link: "https://www.academia.edu/116319637/The_Impact_of_War_on_Children"
    },
    {
        name: "An Analysis of Postgreduate Theses Conducted in Turkey on The Subject of Femicide in The Media",
        photo: "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1712953220/sevinj-aliyeva-blog-media/trkf74jgnjzef5amwh1l.jpg",
        link: "https://www.academia.edu/116897972/AN_ANALYSIS_OF_POSTGRADUATE_THESES_CONDUCTED_IN_TURKEY_ON_THE_SUBJECT_OF_FEMICIDE_IN_THE_MEDIA"
    },
]