import React from 'react'
import '../assets/Css/blog.css';
import Article from './Article';
import { ARTICLES } from '../repo/articles';
import { Link, useNavigate } from 'react-router-dom';
const ArticlesSection = () => {

    const navigate = useNavigate()


  return (
    <section className='blog section'>
        <div className="container">
            <div className="blogContent content column">
                <div className="name">
                    <h3>My Articles</h3>
                </div>
                <div className="cards flex">
                    {ARTICLES.map((article, key)=><Link to={article.link} className='cardLink' target='_blank' key={key}><Article name={article.name} photo={article.photo}/></Link>)}
                </div>
            </div>
        </div>

        {/* <Link to={article.link} target='blank'><Article name={article.name} photo={article.photo}/></Link> */}
    </section>
  )
}

export default ArticlesSection