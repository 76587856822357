import React, { useRef } from 'react'
import { FaSquareInstagram, FaLinkedin, FaFacebook, FaDiscord } from "react-icons/fa6";
import '../assets/Css/footer.css';
import emailjs from '@emailjs/browser';
import { LINK_INSTAGRAM, LINK_LINKEDIN } from '../repo/constants';

export default function Footer() {

  const handleSubmit = (e) => {
    e.preventDefault();
    let fullname = document.getElementById("user_name").value;
    let email = document.getElementById("user_email").value;
    let message = document.getElementById("message").value;

    console.log(fullname, email, message);

    e.preventDefault();

    const body = `Fullname: ${fullname} \nEmail: ${email}  \n\nMessage: ${message}`;

    emailjs
      .send('service_sevinj123', 'template_rvs5yaa', { body: body }, {
        publicKey: 'zToOH_9PSzPH2iptr'
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }

  return (
    <footer className='footer'>
      <div className="footerContent">
        <div className="container">
          <div className="topPart column">
            <div className="sectionTxts">
              <h2>Contact</h2>
              <p>If you have any question or in any case if you want to contact me, Please fill forms below and send your message. I'll touch you back ASAP. Thank you!</p>
            </div>
            <div className="icons">
              <ul className="iconList flex">
                <li className='icon' ><a href={LINK_INSTAGRAM} target='_blank'>
                  <FaSquareInstagram size={30} color='black' />
                </a></li>
                <li className='icon'><a href={LINK_LINKEDIN} target='_blank'>
                  <FaLinkedin size={30} color='black' />
                </a></li>
              </ul>
            </div>
            <div className="formDiv">
              <form className='form flex' onSubmit={(e) => handleSubmit(e)}>
                <input type="text" placeholder='Name Surname' id='user_name' required />
                <input type="email" placeholder='Email' id='user_email' required />
                <input type="text" placeholder='Message' className='message' id='message' required />
                <button type='submit' className='btn'>Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
