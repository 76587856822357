import React from "react";
import { EXPERINCE, LINK_EXPERIENCE } from "../repo/constants";
import "../assets/Css/testi.css";
import Esma from "../assets/photos/esma.jpg";
import Togrul from "../assets/photos/togrul.jpg";
import Bahar from "../assets/photos/bahar.jpg";

import "../assets/Css/experience.css";
import Addim from "../assets/photos/addim.jpg";
import Aspire from "../assets/photos/aspire.jpg";
import Europe from "../assets/photos/europion.jpg";
import Milli from "../assets/photos/milliEgitim.jpg";
import Sos from "../assets/photos/sos.jpg";
import Tehsil from "../assets/photos/tehsilNazirliyi.jpg";

const Experience = () => {
  return (
    <section className="experience section">
      <div className="experienceContent">
        <div className="container">
          <div className="expContent content column">
            <div className="introText">
              <h2>
              Welcome! Let me introduce myself:
              </h2>
            </div>
            <div className="introImageContainer column">
              <div className="expText">
                <p>
                  <b>Sevinj Aliyeva</b> graduated from Ondokuz Mayıs University,
                  Department of Social Work and received the title of cultural
                  ambassador by taking private lessons within the scope of the
                  Cultural Ambassadors Program. She took courses at the
                  Department of Sociology at Istanbul University. She is also
                  one of the winners of Harvard's Aspire program. <br />
                  <br />
                  She opened her country's first Social Work with Children
                  course and has graduated over 50 people from the course to
                  date. She is the founder of the Social Development Platform.{" "}
                  <br />
                  She is a social worker, mentor and trainer with over 5 years of
                  experience. She has more than 4 years of experience in the
                  field of mentoring. She provided mentoring support to more
                  than 80 people in the field of personal development. She is
                  one of the selected mentors of Pasha Holding's Elachi
                  mentoring program. <br />
                  <br />
                  She worked as a social worker at Çodem (Child Support Centre),
                
                ÇOKİM (Child Protection First Response and Evaluation Unit)
                  and Halide Edib Adıvar Kindergarten. She has trained more than
                  1500 people to date. She works mostly with vulnerable groups.
                  <br />
                  Currently, she produces content in her own language by sharing
                  various topics on her Instagram blog regarding children's
                  rights and personal development. For more information, please
                  take a look at the Instagram account:
                </p>
                <a
                  href="https://www.instagram.com/sevinjaliiyeva?igsh=eDg1dnZ1OWtwemRu"
                  target="blank"
                >
                  Sevinc Aliyeva
                </a>
              </div>
              <div className="introImageDiv flex">
                <img
                  className="introImage"
                  src="https://res.cloudinary.com/ddpr4hz2f/image/upload/v1712988778/sevinj-aliyeva-blog-media/eqdc3xrd1hqupw7543qd.jpg"
                />
              </div>
            </div>

            <div className="name expName">
              <h3>Explore my professional journey</h3>
              <p>
                In my diverse career as a social worker, mentor, and supervisor,
                I've collaborated with exceptional colleagues who graciously
                served as mentors. My journey has been marked by a steadfast
                commitment to upholding children's rights and fulfilling the
                vital role of an educator. In this enriching voyage, I've
                encountered a tapestry of individuals, each remarkable in their
                own right. These mentors, drawn from the rich fabric of our
                community, have generously shared their wisdom and guidance.
                Their testimonials stand as a testament to our collective
                endeavors and the profound lessons learned along the way. I am
                excited to present to you a curated collection of reflections
                from these extraordinary mentors. Their words offer a glimpse
                into the collaborative spirit that has been a cornerstone of our
                interactions, and the impactful contributions they've made to
                our shared mission.
              </p>
            </div>
            <div className="testi">
              <div className="testiContent">
                  <div className="content column">
                    <div className="testiCards flex">
                      <div className="testiCard column">
                        <div className="testiImage">
                          <img src={Togrul} alt="img" className="img" />
                        </div>
                        <div className="texts">
                          <h3 className="name">Togrul Alakbarov</h3>
                          <p className="explanation">
                            Head of Central Career Branch of Azerbaijan Public
                            Employment Agency
                          </p>
                          <p>
                            I am delighted to recommend Sevinc for any role that
                            requires a blend of exceptional management skills,
                            mentorship prowess, and a dedicated commitment to
                            personal and professional development. As a former
                            colleague and team leader at our projects, Sevinc
                            consistently demonstrated strong leadership
                            abilities, strategic thinking, and a genuine passion
                            for guiding and empowering others. Her contributions
                            played a pivotal role in the success of our
                            programs, and her mentorship has left a lasting
                            impact on the individuals she has supported. With
                            her outstanding communication skills and unwavering
                            dedication, I have no doubt that Sevinc will excel
                            in any endeavor she pursues.
                          </p>
                        </div>
                      </div>
                      <div className="testiCard column">
                        <div className="testiImage">
                          <img src={Esma} alt="img" className="img" />
                        </div>
                        <div className="texts">
                          <h3 className="name">Esma Pınarbaşı</h3>
                          <p className="explanation exp2">
                            Samsun Provincial Directorate of Family and Social
                            Services (ÇOKİM), Psychologist
                          </p>
                          <p className="recommendation2">
                            I am sure that this hard-working and compassionate
                            girl, who has no problems establishing relationships
                            with people, will go further in social services with
                            her idealistic attitude. Complies with teamwork and
                            rules. She offers creative ideas even in specific
                            cases. She is empathetic, fun, and friendly. The
                            Azerbaijani social work community is very lucky to
                            have him.
                          </p>
                        </div>
                      </div>
                      <div className="testiCard column">
                        <div className="testiImage">
                          <img src={Bahar} alt="img" className="img" />
                        </div>
                        <div className="texts">
                          <h3 className="name">Bahar Mammadova</h3>
                          <p className="explanation">
                            Project Coordinator at Elachi
                          </p>
                          <p className="recommendation3">
                            "Sevinj is an invaluable asset to our team at
                            Elachi, especially in his mentor role. His
                            dedication, responsibility and outstanding results
                            speak volumes about his commitment to the success
                            and growth of our mentees. Sevinj's ability to
                            motivate and support mentees Education and career
                            Her positive attitude and valuable support make her
                            a role model for both mentees and team members. She
                            is also one of the winning mentors throughout the
                            three-month program and I highly recommend Sevinj
                            for her remarkable contribution and remarkable
                            impact on our project. I do.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="logos column">
              <h3 className="logosHeadText">
                Throughout my career, I have had a variety of important and
                lasting work experiences. Please feel free to review the
                information shared to see these experiences more closely.
              </h3>
              <div className="expLogos flex">
                <div className="expLogo column europe">
                  <div className="logoImage">
                    <img src={Europe} alt="" className="expLogoImg" />
                  </div>
                  <div className="logoTitle">
                    <p>
                      "The European Union In the Name of Gender Equality:
                      Against Gender Stereotyping and Gender-Based Violence"
                    </p>
                    <p>Project Manager\Lead Speaker</p>
                    <p>
                      Online trainings were organized for people with different
                      educational backgrounds. These trainings were held with
                      the participation of psychologists, guides of the
                      country's well-known children's and women's shelters, and
                      many well-known names in their fields. The management and
                      reporting of these trainings were ensured.
                    </p>
                  </div>
                </div>
                <div className="expLogo milli column">
                  <div className="logoImage">
                    <img src={Milli} alt="" className="expLogoImg" />
                  </div>
                  <div className="logoTitle">
                    <p>Ministry of Education Halide Edip Adıvar Kindergarten</p>
                    <p>Social Worker</p>
                    <p>
                      In order to contribute to the mental and physical healthy
                      development of students, Physical, Social, and Emotional
                      game workshops were held and lessons were given
                      appropriate to their development levels. A 1-on-1 session
                      of counseling was held with children aged 7-9 and their
                      families.
                    </p>
                  </div>
                </div>
                <div className="expLogo addim column">
                  <div className="logoImage">
                    <img src={Addim} alt="" className="expLogoImg" />
                  </div>
                  <div className="logoTitle">
                    <p>
                      "Addim. at" (Take a Step) Personal Development Platform
                    </p>
                    <p>Personal development mentor, supervisor</p>
                    <p>
                      <ul>
                        <li>
                          - Individual mentoring service for more than 100
                          people
                        </li>
                        <li>- Supervision of more than 40 mentors</li>
                        <li>
                          - Training for more than 1500 project participants in
                          total
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="expLogo column sos">
                  <div className="logoImage">
                    <img src={Sos} alt="" className="expLogoImg" />
                  </div>
                  <div className="logoTitle">
                    <p>
                      Child Protection First Response and Assessment Unit
                      (ÇOKİM)
                    </p>
                    <p>Social Worker</p>
                    <p>
                      Child Support Center (Samsun, Türkiye) Social worker
                      <ul>
                        <li>
                          - Case management with 14 child victims of abuse
                        </li>
                        <li>- Evaluation of various cases</li>
                        <li>
                          - I worked in the implementation of community and
                          family-based integrated social services.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="expLogo column aspire">
                  <div className="logoImage">
                    <img src={Aspire} alt="" className="expLogoImg" />
                  </div>
                  <div className="logoTitle">
                    <p>Harvard Aspire Lidership program</p>
                    <p>Winner</p>
                    <p>
                      As the winner of the Aspire Leadership Programme, I
                      embarked on a transformative journey that nurtures my
                      potential. This program, which consists of three modules,
                      has provided me with a platform to develop both personally
                      and professionally. Using resources created in conjunction
                      with Harvard Business School, I participated in a
                      four-week exploration of personal strengths and leadership
                      skills. I completed a six-week leadership development
                      course designed and delivered by Harvard University
                      faculty. Finally, I successfully completed this program by
                      designing a project for social problems in our country.
                    </p>
                  </div>
                </div>
                <div className="expLogo column tehsil">
                  <div className="logoImage">
                    <img src={Tehsil} alt="" className="expLogoImg" />
                  </div>
                  <div className="logoTitle">
                    <p>
                      Ministry of Science and Education of the Republic of
                      Azerbaijan
                    </p>

                    <p>Social Worker\Educator</p>
                    <p>
                      Within the scope of the "Education is My Future" project,
                      which lasted two days in Samadabad, Rüstemli, and Salahlı
                      village schools in the Yevlah district, discussions were
                      held with a team of more than 50 parents and teachers
                      about the university and career preferences of children.
                      Discussions and plans were made about the students'
                      problems in the courses and the ways to solve these
                      problems. He supported both parties by collaborating with
                      families, especially regarding the education of girls and
                      all students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="expContext flex">
              <div className="expText">
                <p>
                  <b>SOS Children's Villages</b> <br />
                  From the training on Children's Rights in the Media.
                </p>
              </div>
              <div className="expImage">
                <img
                  src="https://res.cloudinary.com/ddpr4hz2f/image/upload/v1711438495/sevinj-aliyeva-blog-media/rllar5qm9ehvnwwywwqe.jpg"
                  alt="photo"
                  className="expImg"
                />
              </div>
            </div>

            <div className="expContext flex">
              <div className="expText">
                <p>
                  <b>"Education is my future" project in Yevlakh.</b> <br />
                  Two "Education is my future" projects were held daily in
                  Samadabad, Rustamli and Salahli village schools of Yevlakh
                  district, and discussions were held regarding the selection of
                  specialties, monitoring of lessons and problems related to a
                  total of more than 50 teachers and teaching staff.
                  <br />
                  <br /> We touched on the following topics in the training:
                  <ul>
                    <li>treatment of education</li>
                    <li>children's rights</li>
                    <li>complications of early marriage</li>
                    <li>The correct role in children's education</li>
                    <li>Children's rights.</li>
                  </ul>
                </p>
              </div>
              <div className="expImage">
                <img
                  src="https://res.cloudinary.com/ddpr4hz2f/image/upload/v1711438629/sevinj-aliyeva-blog-media/ohdqnei3z0gyekj8k9n7.jpg"
                  alt="photo"
                  className="expImg"
                />
              </div>
            </div>

            <div className="expContext flex">
              <div className="expText">
                <p>
                  <b>ÇODEM</b>
                  <br />
                  Social work specialist
                  <ul>
                    <li>
                      Case management with 14 child victims of exploitation
                    </li>
                    <li>Evaluation of various cases</li>
                    <li>
                      Conducting community and family-based integrated
                      socialservice
                    </li>
                  </ul>
                </p>
              </div>
              <div className="expImage">
                <img
                  src="https://res.cloudinary.com/ddpr4hz2f/image/upload/v1711437994/sevinj-aliyeva-blog-media/bwyfjwu55bsjju78ulh5.jpg"
                  alt="photo"
                  className="expImg"
                />
              </div>
            </div>

            <div className="expContext flex">
              <div className="expText">
                <p>
                  <b>Halide Edip Adivar Anaokulu </b>
                  <br />
                  Social work specialistin a preschool institution
                  <ul>
                    <li>
                      Solving psychosocial problems of more than 20 students
                    </li>
                    <li>
                      Connecting students and their families to community
                      resources
                    </li>
                    <li>
                      Improving the quality of the educational environment
                    </li>
                    <li>
                      Carrying out work related to the prevention of drug
                      addiction
                    </li>
                  </ul>
                </p>
              </div>
              <div className="expImage">
                <img
                  src="https://res.cloudinary.com/ddpr4hz2f/image/upload/v1712952829/sevinj-aliyeva-blog-media/k7iqpnjdxiaqi8cjfgmk.jpg"
                  alt="photo"
                  className="expImg"
                />
              </div>
            </div>

            <div className="expContext flex">
              <div className="expText">
                <p>
                  <b>Association of Young Social Workers </b>
                  <br /> Vice-coordinator
                  <ul>
                    <li>
                      Implementation of local and foreign social projects
                      through the association
                    </li>
                    <li>
                      Organization of the sign language course "Hands Speak" in
                      order to contribute to the creation of an inclusive
                      society
                    </li>
                    <li>Series of trainings in the regions</li>
                  </ul>
                </p>
              </div>
              <div className="expImage">
                <img
                  src={
                    "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1712952934/sevinj-aliyeva-blog-media/tjhz5y3l0utwyikiyxrp.jpg"
                  }
                  alt="photo"
                  className="expImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
