import React from 'react'
import '../assets/Css/project.css'
import { PROJECTS } from '../repo/projects'


const Projects = () => {
    return (
        <section className='projectSection section'>
            <div className="proje">
                <div className="container">
                    <div className="projectContent content column">
                        <div className="name">
                            <h3>Projects</h3>
                        </div>
                        <div className="projects column">
                            <div className="project flex projectOne">
                                <div className="projectImage firstProjectImage">
                                    <img src={PROJECTS[0].photo} alt="projectPhoto" className='projectPhoto' />
                                </div>
                                <div className="projectText"> <p>
                                    <b>Social Development Platform</b>, whose founder is <b>Sevinj Aliyeva</b>, is a platform that aims to provide training on various subjects. The training programs organized here are carried out by local and foreign experts.
                                    <br/><br/>
                                    In particular, the following issues are addressed in <b>SDP</b>:<br/>
                                    <i><ul>
                                    <li>Training related to social work</li>
                                    <li>Training on personal development</li>
                                    <li>Corporate or individual training</li>
                                    <li>Masterclasses and workshops</li>
                                    </ul></i>
                                    <br/><br/>
                                    The <b>"Social Service Summit"</b> was held for the first time in our country by the platform with 10 foreign experts and over 100 participants.
                                    </p><br/><p>For more information: <a href='https://www.instagram.com/sdp.az/'>Instagram</a></p>
                                </div>
                            </div>
                            <div className="project flex projectTwo">
                                <div className="projectImage">
                                    <img src={PROJECTS[1].photo} alt="projectPhoto" className='projectPhoto' />
                                </div>
                                <div className="projectText">
                                    <p>The case-based course <b>"Social Work with Children"</b>, organized for the first time in Azerbaijan, was created by Sevinj Aliyeva. The 4-day training program, which has been held 3 times so far, is based on practical information, especially real cases. During the training, participants benefit from various opportunities such as applying what they have learned in practice, handling real cases as a group with their group friends, learning about foreign country experiences, and establishing a quality network with other social workers. Various social workers also attend this course as guests and share their experiences and knowledge with the participants. The course is important not only for students but also for experienced social workers working in the field. The graduates of the case-based "Social Work with Children" course, which has graduated over 50 to date, include social workers working in various ministries, and private and state sectors.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects