import React from 'react'
import Navbar from './Navbar'
import Hero from './Hero'
import Footer from './Footer'
import ArticlesSection from './ArticlesSection'
import Testimonial from './Testimonial'
import Experience from './Experience'
import Projects from './Projects'
export default function Dashboard() {
  return (
    <div>
        <Navbar/>
        <Hero/>
        <Experience/>
        <Projects/>
        <ArticlesSection/>
        {/* <Testimonial/> */}
        <Footer/>
    </div>
  )
}
