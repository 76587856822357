import React, { useState } from "react";
import "../assets/Css/navbar.css";
import { Link, ScrollLink } from "react-scroll";
import Logo from "../assets/photos/logo-nav.png"

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="headerContent flex">
          <div className="logo">
            <Link to='hero' smooth={true} duration={1000} className="logoLink">
              <img src={Logo} alt="logo" className="logoImg" />
            </Link>
          </div>
          <div className="headerRight">
            <div className={`menuIcon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
              <ul className="navList flex">
                <li className="listItem"><Link to='hero' smooth={true} duration={1000} className='navLink'>About</Link></li>
                <li className="listItem"><Link to='experience' smooth={true} duration={1000} offset={-60} className='navLink'>Experience</Link></li>
                <li className="listItem"><Link to='projectSection' smooth={true} duration={1000} offset={-60} className='navLink'>Projects</Link></li>
                <li className="listItem"><Link to='blog' smooth={true} duration={1700} offset={-60} className='navLink'>Articles</Link></li>
                {/* <li className="listItem"><Link to='testi' smooth={true} duration={1000} offset={-60} className='navLink'>Recommends</Link></li> */}
                <li className="listItem"><Link to='footer' smooth={true} duration={2000} className='navLink'>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
