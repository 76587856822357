export const EXPERINCE = "Sevinj Aliyeva graduated from Ondokuz Mayis University, Department of Social Work, and received the title of cultural ambassador by taking private lessons within the scope of the Cultural Ambassadors Program. She took courses at the Sociology Department of Istanbul University. She is also one of the winners of Harvard's Aspire program. She opened the first Social Work with Children course in her country and has graduated over 50 people from the course so far. She is the founder of the Social Development Platform. He is a social worker, mentor, and trainer with over 5 years of experience. She has more than 4 years of experience in the field of mentoring. She provided mentoring support to more than 80 people in the field of personal development. She worked as a social worker at ÇODEM (Child Support Centre), ÇokİM (Child Protection First Response and Evaluation Unit), and Halide Edib Adivar Kindergarten. To date, he has trained more than 1500 people. She works mostly with vulnerable groups."

export const LINK_EXPERIENCE = "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1711365228/sevinj-aliyeva-blog-media/fadx4ncbxugyfnhhyt2k.jpg"

// export const LINK_PROFIL_PHOTO = "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1710659221/sevinj-aliyeva-blog-media/k6dsggcwtrpjbrtlyrye.jpg"
export const LINK_PROFIL_PHOTO = "https://res.cloudinary.com/ddpr4hz2f/image/upload/v1719911606/sevinj-aliyeva-blog-media/vk4pfs7yhizadabppbpb.webp"

export const LINK_LINKEDIN = "https://www.linkedin.com/in/sevinj-aliyeva-00a4a3123/"

export const LINK_INSTAGRAM = "https://www.instagram.com/sevinjaliiyeva/"

export const ABOUT = "I am Sevinj Aliyeva: a social worker who loves learning and teaching, and raising awareness by adding value. If we don't do it, who will?"