import React from 'react'
import '../assets/Css/hero.css'
// import { ABOUT } from '../repo/constants'
import { LINK_PROFIL_PHOTO } from '../repo/constants'
const Hero = () => {
  return (
    <section className='hero section'>
        <div className="heroContent">
            <div className="container">
                <div className="context flex">
                    <div className="heroImg">
                        <img src={LINK_PROFIL_PHOTO} alt="Sevinc" className='heroImage'/>
                    </div>
                    <div className="text">
                        <p className='aboutText'>I am <b>Sevinj Aliyeva</b>: a social worker who loves learning and teaching, and raising awareness by adding value. 
                        <br/> <b>If we don't do it, who will?</b></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero